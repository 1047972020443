.page-header, .page-sub-header {
	padding-bottom: 9px;
	border-bottom: 1px solid $brand-primary;
}

.page-header {
	margin: 2em 0 3em;
}

.page-sub-header {
	margin: 4em 0 3em;
}

.main {
	margin: 3em 0;
}

.content {
	margin-bottom: 3em;
}

.page-template-front-page {
	.welcome {
		h1, h2, h3, h4, h5, h6, p {
			line-height: 2em;
		}
	}
	section {
		padding: 4em 0;
	}
	.gallery {
		background: $brand-secondary;
	}
}

.page-template-template-characters {
	.character {
		min-height: 450px;
		img {
			padding: .6em;
		}
		.character-type {
			text-transform: uppercase;
		}
		.more-info {
			margin: 1em 0 .5em;
		}
		.modal-content {
			margin-top: 8em;
			.modal-body {
				padding: 1.2em 2.3em;
			}
		}
	}
}

.page-template-template-testimonials {
	footer {

	}
}

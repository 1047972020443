footer.content-info {
	background: $brand-tertiary;
	padding: 2em;
	.contact-details-wrap {
		color: $white;
		text-align: center;
		@media (min-width: $screen-md-min) {
			text-align: left;
		}
	}
	.social-media-link-wrap {
		margin-top: .2em;
		font-size: 2em;
		text-align: center;
		.social-media-link {
			color: $white;
			margin: 0em .2em;
			&:hover {
				color: $brand-primary;
			}
		}
		@media (min-width: $screen-md-min) {
			text-align: right;
		}
	}
}

// Search form
.search-form {
  @extend .form-inline;
}
.search-form label {
  font-weight: normal;
  @extend .form-group;
}
.search-form .search-field {
  @extend .form-control;
}
.search-form .search-submit {
  @extend .btn;
  @extend .btn-default;
}

// Contact form
.ninja-forms-form {
	.ninja-forms-required-items {
		margin-bottom: 1em;
	}
	label {
		padding: .3em .2em .6em;
		font-size: $font-size-large;
	}
	input, textarea {
		padding: .7em 1em;
		border-radius: 8px;
		border: 1px solid #D3D3D3;
		&:hover, &:active, &:focus {
			border-radius: 8px;
		}
	}
	textarea {
		resize:vertical;
	}
	input[type="submit"] {
		padding: .7em 2em;
		background: $brand-primary;
		color: $white;
		float: right;
		font-size: $font-size-large;
	}
}

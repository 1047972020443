.navbar {
	margin-bottom: 0;
	background-color: $white;
	// .navbar-brand {
	// 	padding-top: 0;
	// 	.navbar-logo-wrap {
	// 		max-width: 50px;
	// 		display: none;
	// 		@media (min-width: $screen-sm-min) {
	// 			display: none;
	// 		}
	// 	}
	// }
	.navbar-nav {
		padding: 0;
		.active > a {
			border-radius: 8px;
			color: $white;
			background: $brand-primary;
			&:hover, &:active, &:focus {
				color: $white;
				background: $brand-primary;
				border-radius: 8px;
			}
		}
	}
	@media (min-width: $screen-sm-min) {
		padding: 1.5em 0;
	}
}

header {
	background-size: cover;
	.home-header-logo-wrap, .default-header-logo-wrap {
		.logo-image-wrap {
			max-width: 550px;
			margin: 0 auto;
			img {
			}
		}
	}
	.home-header-logo-wrap {
		margin: 9.5em 0;
	}
	.default-header-logo-wrap {
		margin: 6.25em 0;
	}
	.home-header-subtitle {
		margin-top: 1.5em;
	}
}
